import React, { Suspense, useRef, useState, useEffect } from "react"
import { useFrame } from "@react-three/fiber"
import { useGLTF } from "@react-three/drei"

import { useAppContext } from "../../stores/appStore"
import gsap from "gsap"
import RocketBackRay from "./Rocket/RocketBackRay"
import RocketFlame from "./Rocket/RocketFlame"

function Rocket() {
    const [fly, setFly] = useState(false)
    const { scene: rocketScene } = useGLTF("/models/rocket.glb")
    const rocketRef = useRef()
    const pivotRef = useRef()
    const initialYPosition = useRef(0)
    const { prepareDraw, altitude, history, altitudeAnimationInProgress } =
        useAppContext()

    const defaultPosition = () => {
        gsap.set(pivotRef.current.rotation, {
            x: 0,
            z: -1.4,
            y: 1
        })
        gsap.set(pivotRef.current.position, {
            x: -6.8,
            y: -1.8,
            z: -3.2
        })
    }

    const flyPosition = () => {
        gsap.to(pivotRef.current.position, {
            x: 2,
            y: 1,
            z: -4,
            duration: 3,
            ease: "power4.inOut"
        })
        gsap.to(pivotRef.current.rotation, {
            x: 0,
            y: 0.4,
            z: -1.1,
            duration: 3,
            ease: "power4.inOut"
        })
    }

    const preparePosition = () => {
        gsap.to(pivotRef.current.position, {
            x: -4,
            y: -1.3,
            z: -5,
            duration: 2,
            ease: "power2.inOut"
        })
        gsap.to(pivotRef.current.rotation, {
            x: 0,
            z: -1.4,
            y: 1,
            duration: 2,
            ease: "power2.inOut"
        })
    }

    const flyAwayPosition = () => {
        gsap.to(pivotRef.current.position, {
            x: pivotRef.current.position.x + 5,
            y: pivotRef.current.position.y + 2.5,
            z: pivotRef.current.position.z - 2,
            duration: 1,
            ease: "power2.inOut"
        })
    }

    const flyAndFlyAwayPosition = () => {
        gsap.to(pivotRef.current.position, {
            x: 7,
            y: 3,
            z: -7,
            duration: 3,
            ease: "power4.inOut"
        })
        gsap.to(pivotRef.current.rotation, {
            x: 0,
            y: 0.4,
            z: -1.1,
            duration: 3,
            ease: "power4.inOut"
        })
    }

    useFrame(({ clock }) => {
        if (rocketRef.current) {
            rocketRef.current.rotation.y += 0.003 // Rotate rocket
            // Increment time

            // if (!prepareDraw && altitude) {
            rocketRef.current.position.y =
                initialYPosition.current +
                Math.sin(clock.getElapsedTime() * 2) * 0.1
            // }
        }
    })

    // Animate the rocket to fly away
    useEffect(() => {
        if (pivotRef.current) {
            // flyAwayPosition()

            if (prepareDraw || altitude) {
                preparePosition()
            } else {
                defaultPosition()
            }

            if (!prepareDraw && altitude) {
                if (altitude < 1.21) {
                    flyAndFlyAwayPosition()
                } else {
                    flyPosition()
                }
                setFly(true)
            }
        }
    }, [altitude, prepareDraw])

    // Start flyAway animation
    useEffect(() => {
        // flyPosition()
        if (!altitudeAnimationInProgress && fly) {
            flyAwayPosition()
        }
    }, [altitudeAnimationInProgress])

    // Reset position
    useEffect(() => {
        defaultPosition()
        setFly(false)
    }, [history])

    return (
        <Suspense fallback={null}>
            <group ref={pivotRef} rotation={[0, 0, 0]}>
                <group ref={rocketRef}>
                    <primitive object={rocketScene} scale={0.5} castShadow />
                    <pointLight
                        position={[0, 0, 0]} // Adjust position relative to the rocket
                        intensity={0.7} // Brightness of the light
                        distance={1} // How far the light reaches
                        decay={2} // Light decay over distance
                        color={0xffff00} // Light color (white)
                    />
                    <RocketBackRay color={0x00ff00} />
                    <RocketFlame />
                    {/* <RocketFlame /> */}
                </group>
            </group>
        </Suspense>
    )
}

export default Rocket
