import React from "react"
import Board from "../components/jsx/Board"
import Countdown from "../components/jsx/Countdown"
import Header from "../components/jsx/Header"
import Multiplier from "../components/jsx/Multiplier"
import RoundHistory from "../components/jsx/RoundHistory"
import Scene from "../components/Scene"
import ActualRoundNo from "./jsx/ActualRoundNo"
import TopWins from "./jsx/TopWins"
import Jackpot from "./jsx/Jackpot"

export default function Display() {
    return (
        <div className="App">
         
            <Scene />
            <Header />
            <Board />
            <Multiplier />
            <ActualRoundNo />
            <Countdown />
            <TopWins />
            <RoundHistory />
            <Jackpot />
            
        </div>
    )
}
