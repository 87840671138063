import React, { useEffect, useState } from "react";
import styles from "./Board.module.css";
import { useAppContext } from "../../stores/appStore";

export default function Board() {
    const { showBoard, remainingSeconds, jackpotHit } = useAppContext();
    const [secondPage, setSecondPage] = useState(false);

    useEffect(() => {
        let intervalId;

        if (showBoard && !jackpotHit) {
            // Alternate pages every 5 seconds
            intervalId = setInterval(() => {
                setSecondPage((prev) => !prev);
            }, 5000);
        } else {
            // Reset to the first page when `showBoard` becomes true
            setSecondPage(false);
        }

        return () => {
            // Cleanup the interval on unmount or when showBoard changes
            clearInterval(intervalId);
        };
    }, [showBoard, jackpotHit]);

    useEffect(() => {
        // Reset to the first page when `remainingSeconds` reaches 0
        if (remainingSeconds === 0) {
            setSecondPage(false);
        }
    }, [remainingSeconds]);

    return (
        <div
            className={`${styles.board} ${
                showBoard && !jackpotHit && styles.show
            }`}
        >
            <div className={styles.offset}></div>
            <div className={styles.inner}>
                {!secondPage ? (
                    <>
                        <div className={styles.title}>Cum se joacă?</div>
                        <div className={styles.text}>
                            Alegeți cota pe care doriți sa o atingeți înainte ca
                            racheta să zboare de pe ecran.
                        </div>
                    </>
                ) : (
                    <>
                        <div className={styles.title}>Cum câștig?</div>
                        <div className={`${styles.text} ${styles.second}`}>
                            Dacă la finalul rundei cota aleasă este mai mică sau
                            egală cu cea finală, ați câștigat.
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
