import React, { useState, useEffect } from "react"
import styles from "./Jackpot.module.css"
import { useAppContext } from "../../stores/appStore"

function Jackpot() {
    const { jackpotHit, shopId } = useAppContext()

    const [display, setDisplay] = useState(false)
    useEffect(() => {
        if (
            jackpotHit &&
            (jackpotHit.type === "global" ||
                (jackpotHit.type === "local" && jackpotHit.shopId === shopId))
        ) {
            setTimeout(() => {
                setDisplay(true)
            }, 3300)
        } else {
            setDisplay(false)
        }
    }, [jackpotHit, shopId])

    if (display)
        return (
            <div className={styles.wrapper}>
                <div className={styles.shadow}></div>
                <div className={styles.glass}></div>
                <div className={styles.lights}>
                    <div
                        className={styles.light}
                        style={{ top: 252, right: 406, animationDelay: `1s` }}
                    ></div>
                    <div
                        className={styles.light}
                        style={{ top: 351, left: 276, animationDelay: `3s` }}
                    ></div>
                    <div
                        className={styles.light}
                        style={{ bottom: 205, left: 280, animationDelay: `0s` }}
                    ></div>
                    <div
                        className={styles.light}
                        style={{ bottom: 75, right: 387, animationDelay: `2s` }}
                    ></div>
                </div>
                <div className={styles.details}>
                    <div className={styles.title}>JACKPOT</div>
                    <div className={styles.value}>{jackpotHit?.amount} RON</div>
                    <div className={styles.location}>
                        {jackpotHit?.shopName}
                    </div>
                </div>
            </div>
        )
}

export default React.memo(Jackpot)
