import { createContext, useContext, useState, useEffect } from "react"
import { HubConnectionBuilder, LogLevel } from "@aspnet/signalr"
import axios from "axios"

const AppContext = createContext()

export function useAppContext() {
    return useContext(AppContext)
}

export function AppContextProvider({ children }) {
    const [connection, setConnection] = useState(null)
    const [shopId, setShopId] = useState(null)
    const [rocketTable, setRocketTable] = useState([])
    const [remainingSeconds, setRemainingSeconds] = useState(null)
    const [altitude, setAltitude] = useState(null)
    const [altitudeAnimationInProgress, setAltitudeAnimationInProgress] =
        useState(false)
    const [animationFinished, setAnimationFinished] = useState(false)
    const [showWins, setShowWins] = useState(false)
    const [nextRoundNo, setNextRoundNo] = useState(null)
    const [actualRoundNo, setActualRoundNo] = useState(null)
    const [lastRoundNo, setLastRoundNo] = useState(null)
    const [lastRoundAltitude, setLastRoundAltitude] = useState(null)
    const [localJackpot, setLocalJackpot] = useState(null)
    const [globalJackpot, setGlobalJackpot] = useState(null)
    const [history, setHistory] = useState([])
    const [prepareDraw, setPrepareDraw] = useState(false)
    const [topWins, setTopWins] = useState([])
    const [showBoard, setShowBoard] = useState(false)
    const [jackpotHit, setJackpotHit] = useState(null)

    // Get ShopID
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const shopIdParam = urlParams.get("shopId")
        setShopId(shopIdParam)
    }, [])

    // Fetch initial data using axios
    useEffect(() => {
        getTopWins()
        axios
            .get(
                "https://tbet.oddin-platform.com/virtualsapi/api/utils/payTable/Rocket"
            )
            .then((response) => {
                // console.log("Fetched Data:", response.data)
                setRocketTable(response.data) // Example of setting the data
            })
            .catch((error) => {
                console.error("Error fetching initial data:", error)
            })
    }, [])

    // Setup SignalR connection and handlers
    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(
                "https://tbet.oddin-platform.com/virtualsweb/RocketHub?shopId=5eb84ff703293700012f18e9"
            )
            // .configureLogging(LogLevel.Debug)
            // .withAutomaticReconnect() // Enable automatic reconnect if needed   (NOT AVAILABLE ON)
            .build()

        setConnection(newConnection)

        // Start connection
        if (newConnection) {
            startConnection(newConnection)
        }

        // Cleanup connection on component unmount
        return () => {
            if (newConnection) {
                newConnection.stop()
                console.log("SignalR connection stopped.")
            }
        }
    }, [])

    // GET TOP WINS REQUEST
    const getTopWins = () => {
        axios
            .get(
                "https://tbet.oddin-platform.com/virtualsapi/api/rocket/topWins/5/3"
            )
            .then((response) => {
                setTopWins(response.data)
                setShowWins(true)
            })
            .catch((error) => {
                console.error("Error fetching initial data:", error)
            })
    }

    // Start the SignalR connection and handle events
    const startConnection = async (connection) => {
        try {
            await connection.start()
            console.log("SignalR connection established")

            connection.on("catchUp", (message) => {
                // console.log("catchUp:", message)
                setLastRoundNo(message.lastRoundNo)
                if (!message.betClosed) {
                    setNextRoundNo(message.actualRoundNo)
                }

                setLastRoundAltitude(message.lastRoundAltitude)
                setLocalJackpot(message.localOngoingJackpotAmount)
                setGlobalJackpot(message.globalOngoingJackpotAmount)
                // setHistory(message.roundHistory)
                setActualRoundNo(message.actualRoundNo)
                setRemainingSeconds(message.remainingRoundSeconds)
            })
            connection.on("remainingRoundTime", (message) => {
                // console.log("remainingRoundTime:", message)
                setRemainingSeconds(message.remainingRoundSeconds)
            })

            connection.on("jackpotInfo", (message) => {
                // console.log("jackpotInfo:", message)

                // UPDATE LOCAL JACKPOT ONGOING AMOUNT
                if (message.localOngoingJackpotAmount) {
                    setLocalJackpot(message.localOngoingJackpotAmount)
                }
                // UPDATE GLOBAL JACKPOT ONGOING AMOUNT
                if (message.globalOngoingJackpotAmount) {
                    setGlobalJackpot(message.globalOngoingJackpotAmount)
                }

                // CHECK AND SHOW IF GLOBAL JACKPOT HIT
                if (message.globalAwardedJackpot) {
                    if (message.globalAwardedJackpot.advertiseTtl > 0) {
                        const { shopName, amount } =
                            message.globalAwardedJackpot
                        setJackpotHit({
                            type: "global",
                            shopName,
                            amount
                        })
                        setTimeout(() => {
                            setJackpotHit(null)
                        }, 10000)
                    }
                }
                // CHECK AND SHOW IF LOCAL JACKPOT HIT ( DE FACUT COMPARATIE DE SHOP ID )
                if (message.localAwardedJackpot) {
                    if (message.localAwardedJackpot.advertiseTtl > 0) {
                        const { shopName, shopId, amount } =
                            message.localAwardedJackpot
                        setJackpotHit({
                            type: "local",
                            shopId,
                            shopName,
                            amount
                        })
                        setTimeout(() => {
                            setJackpotHit(null)
                        }, 10000)
                    }
                }
            })

            connection.on("prepareDraw", (message) => {
                // console.log("prepareDraw:", message)
                setPrepareDraw(true)
                setShowBoard(false)
                setShowWins(false)

                // if (nextRoundNo) {
                //     setActualRoundNo(nextRoundNo - 1)
                // }
                setRemainingSeconds(message.remainingRoundSeconds)
            })
            connection.on("roundClosed", (message) => {
                setAltitude(message.altitude)
                setNextRoundNo(message.newRoundNo)
                // setActualRoundNo(message.newRoundNo - 1)
                setRemainingSeconds(message.remainingRoundSeconds)
                setPrepareDraw(false)
                // console.log("roundClosed:", message)
            })
            connection.on("historyResults", (message) => {
                const delay =
                    parseInt(message.rounds[0]?.altitude) > 3 ? 5000 : 1000

                setTimeout(() => {
                    setHistory(message.rounds)
                    setAltitude(null)
                    getTopWins()
                    // console.log("delayed history", delay)
                }, delay)

                setTimeout(() => {
                    setShowBoard(true)
                    setActualRoundNo((s) => s + 1)
                }, delay + 1000)

                setRemainingSeconds(message.remainingRoundSeconds)

                // console.log("historyResults:", message)
            })
        } catch (error) {
            console.error("Error starting SignalR connection:", error)
        }
    }

    return (
        <AppContext.Provider
            value={{
                shopId,
                altitude,
                setAltitude,
                altitudeAnimationInProgress,
                setAltitudeAnimationInProgress,
                remainingSeconds,
                nextRoundNo,
                localJackpot,
                globalJackpot,
                history,
                prepareDraw,
                topWins,
                rocketTable,
                actualRoundNo,
                lastRoundNo,
                lastRoundAltitude,
                showBoard,
                showWins,
                setShowWins,
                jackpotHit,
                animationFinished,
                setAnimationFinished
            }}
        >
            {children}
        </AppContext.Provider>
    )
}
