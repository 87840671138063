import React, { useState, useEffect } from "react"
import styles from "./Countdown.module.css"
import { useAppContext } from "../../stores/appStore"

export default function Countdown() {
    const [seconds, setSeconds] = useState(5)
    const { prepareDraw } = useAppContext()

    useEffect(() => {
        let interval

        // Start countdown when `prepareDraw` is true
        if (prepareDraw) {
            setSeconds(5) // Reset countdown to 5 seconds when `prepareDraw` is true

            interval = setInterval(() => {
                setSeconds((prev) => {
                    if (prev > 0) {
                        return prev - 1 // Decrease the seconds
                    } else {
                        clearInterval(interval) // Stop the interval when it reaches 0
                        return 0
                    }
                })
            }, 1000) // Run every second
        }

        // Cleanup interval on component unmount or when `prepareDraw` changes
        return () => clearInterval(interval)
    }, [prepareDraw])

    return (
        <div className={`${styles.wrapper} ${prepareDraw && styles.show}`}>
            {seconds > 0 ? seconds : ""}
        </div>
    )
}
